import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/ApiConfig/ApiConfig";
import { calculateTimeLeft, getContract, swichNetworkHandler } from "src/utils";
import { apiRouterCall, getAPIHandler } from "src/ApiConfig/service";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import {
  ACTIVE_NETWORK,
  contractDecimals,
  getBalanceContract,
} from "src/constants";
import GetBalanceContractABI from "src/ABI/GetBalanceContractABI.json";
export const AuthContext = createContext();
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [viewWalletData, setViewWalletData] = useState();
  const [tokenBalance, settokenBalance] = useState("");
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [isPotAmount, setisPotAmount] = useState({});
  const [lotteryBuyDisAble, setLotteryBuyDisAble] = useState(false);
  const [blcLoad, setBlcLoad] = useState(true);



  const walletConnect = async () => {
    try {
      activate(injected, undefined, true)
        .then(() => {
          window.sessionStorage.setItem("ConnectWallet", true);
        })
        .catch((error) => {
          if (error) {
            toast.error(error.message);
            console.log("ERROR", error);
            const errorMSG = error.message; //+ ' Please install Metamask';
            activate(injected);
          }
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getTokenWalletBalance = async () => {
    const contractObjToken = getContract(
      getBalanceContract,
      GetBalanceContractABI,
      library,
      account
    );
    const getTokenBalance = await contractObjToken.balanceOf(account);
    const toStngBl = getTokenBalance.toString();
    // const balancetoken = web3.utils.fromWei(toStngBl);
    settokenBalance(
      parseFloat(Number(toStngBl) / Math.pow(10, contractDecimals)).toFixed(3)
    );
  };

  const viewUserProfile = async (token) => {
    try {
      const response = await axios({
        url: ApiConfig.viewProfile,
        method: "GET",
        headers: {
          token: token,
        },
      });

      if (response.data.responseCode === 200) {
        setUserData(response.data.result);
        localStorage.setItem("userID", response.data.result._id);
        localStorage.setItem("profilePic", response.data.result.profilePic);
        localStorage.setItem("fullName", response.data.result.fullName);
        localStorage.setItem("userName", response.data.result.userName);
        getUserWallletApi();
      } else {
        setUserData({});
      }
    } catch (error) {
      console.log(error);
      window.sessionStorage.removeItem("token");
      setIsLogin(false);
      setSession(null);
      localStorage.removeItem("ADMIN");
      localStorage.removeItem("referralCode");
      localStorage.removeItem("userID");
      localStorage.removeItem("profilePic");
      localStorage.removeItem("fullName");
      localStorage.removeItem("userName");
      window.sessionStorage.removeItem("ConnectWallet");
      account && deactivate();
    }
  };
  const getUserWallletApi = async () => {
    try {
      setBlcLoad(true);
      const response = await getAPIHandler({
        endPoint: "viewWallet",
      });
      if (response.data.responseCode === 200) {
        setViewWalletData(response.data.result);
        console.log(
          "response.data.result.balance",
          response.data.result.balance
        );
        localStorage.setItem("userBalance", response.data.result.balance);
      } else {
        setViewWalletData();
      }
      setBlcLoad(false);
    } catch (error) {
      setBlcLoad(false);
      console.log(error);
      setViewWalletData();
    }
  };

  // lottery list

  const getGlobalLotteryData = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getLottery,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [sessionStorage.getItem("ConnectWallet")]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      viewUserProfile(sessionStorage.getItem("token"));
      // getPotAmountIdApi();
      window.getUserWallletApi = getUserWallletApi;
    }
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    if (account && chainId) {
      getTokenWalletBalance();
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  // useEffect(() => {
  //   getPotAmountIdApi();
  // }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    blcLoad,
    viewWalletData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    account,
    deactivate,
    library,
    tokenBalance,
    isPotAmount,
    openReferralModal,
    setOpenReferralModal,
    lotteryBuyDisAble,
    setLotteryBuyDisAble,
    walletConnect: walletConnect,
    viewUserProfile: viewUserProfile,
    getUserWallletApi: getUserWallletApi,
    getGlobalLotteryData: getGlobalLotteryData,
    // getPotAmountIdApi: getPotAmountIdApi,
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
