import React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
import { FaTelegramPlane } from "react-icons/fa";
import { AiFillInstagram, AiOutlineWhatsApp } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: "30px",
    zIndex: "999",
    position: "relative",
    background: "rgba(255, 255, 255, 0.02)",
    borderTop: "1px solid #2A305E",
    margin: "0 20px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      margin: "0",
    },
    "& .justiContent": {
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        maxWidth: "105px",
      },
    },
    "& .termstext": {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    "& .textcenterBox": {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .textrightBox": {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .socialCenter": {
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    "& svg": {
      color: "#ffb000db",
      fontSize: "24px",
    },
    "& .bordercenterText": {
      marginLeft: "10px",
      borderLeft: "1px solid #2A305E",
      borderRight: "1px solid #2A305E",
      padding: "9px 10px",
    },
    "& .logodes": {
      width: "auto",
      fontSize: "14px",
      maxWidth: "393px",
      marginTop: "-11px",
      color: "#ffffffb5",
      lineHeight: "24px",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
    "& .privacyText": {
      fontSize: "14px",
      color: "#fff",
      cursor: "pointer",
      whiteSpace: "pre",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
        fontWeight: "300 !important",
      },
    },
    "& .displayStart": {
      // justifyContent: "end",
    },
    "& .displaySpacebetween": {
      flexWrap: "wrap",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.mainBox}>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={5} md={5} sm={6} xs={12} className="textcenterBox">
            <Box padding={0}>
              <Logo
                style={{ paddingTop: "0px" }}
                onClick={() => {
                  localStorage.getItem("ADMIN") &&
                    localStorage.removeItem("ADMIN");
                  history.push("/");
                }}
                className="logoDrawerBox"
                alt="Logo"
              />
              <Typography variant="body2" className="logodes">
                A community of passionate gamers who have put together a unique
                gaming platform that upgrades your gameplay experience to a
                whole new level.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className="textcenterBox">
            <Box className="displayStart termstext">
              <Typography
                variant="h6"
                className="privacyText"
                onClick={() => history.push("/features")}
              >
                Our Platform Features
              </Typography>
              <Typography
                variant="h6"
                className="privacyText bordercenterText"
                onClick={() => history.push("/privacy-policy")}
              >
                Privacy Policy
              </Typography>
              {/* <Typography
                  variant="h6"
                  className="privacyText bordercenterText"
                  onClick={() => history.push("/terms-condition")}
                  style={{ marginLeft: "10px" }}
                >
                  Terms Condition
                </Typography> */}
              <Typography
                variant="h6"
                className="privacyText"
                onClick={() => history.push("/about")}
                style={{ marginLeft: "10px" }}
              >
                About Us
              </Typography>
              {/* <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history.push("/features")}
            style={{ marginLeft: "10px" }}
          >
            Features
          </Typography> */}
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className="textrightBox flex flex-col items-center gap-y-2">
            <img
              src="images/join_textname.png"
              alt="images"
              className="justiContent"
            />

            <Box className="displayEnd socialCenter">
              <IconButton
                href="https://t.me/+IK3LR5TPVhs1N2Fl"
                target="_blank"
                className="socialButton"
              >
                <FaTelegramPlane />
              </IconButton>
              <IconButton
                href="https://instagram.com/betbyheart6?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                className="socialButton"
              >
                <AiFillInstagram />
              </IconButton>
              <IconButton
                href="https://www.whatsapp.com/channel/0029VaAbWMT0LKZ8fa1mev2j"
                target="_blank"
                className="socialButton"
              >
                <AiOutlineWhatsApp />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
