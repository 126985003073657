import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { numberCompactFormat } from "src/utils";
import { AiFillHome } from "react-icons/ai";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { currencyUnit } from "src/constants";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: " 5px 0px",
    "& .TopbarButton": {
      margin: "0px !important",
      marginRight: "0px !important",
      fontSize: "14px",
      padding: "8px 18px !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px !important",
        border: "none",
        fontSize: "11px",
      },
    },
  },
  hedaerTopbar: {
    [theme.breakpoints.down("sm")]: {
      height: "37px",
    },
  },
  profileBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  walleticon: {
    [theme.breakpoints.down("sm")]: {
      width: "20px",
    },
  },
}));

export default function Header() {
  const { toolbar, hedaerTopbar, profileBox, walleticon } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (location) {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);
  // let balance = localStorage.getItem("userBalance");
  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
        }}
        className={hedaerTopbar}
      >
        <Container maxWidth="fixed">
          <Toolbar className={toolbar}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {/* <IconButton
                // id="leave-table"
                variant="contained"
                color="secondary"
                size="small"
                style={{ whiteSpace: "pre" }}
                onClick={() =>
                  window.location.pathname === "/leaderboard"
                    ? history.push("/derby")
                    : window.location.pathname === "/black-jack"
                    ? window.open("/", "_self")
                    : history.goBack()
                }
              >
                <MdOutlineArrowBackIos />
              </IconButton> */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <IconButton
                // id="leave-table"
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.terminateCrtUser("home");
                  // if (window.location.pathname === "/blackjack") {
                  //   window.open("/", "_self");
                  // } else {
                  //   history.push("/");
                  // }
                }}
              >
                <AiFillHome />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box className="centerLogoBox balckjacklogo">
                <Link
                  to="/"
                // id="leave-table"
                >
                  <Logo className="logoImg" />
                </Link>
              </Box>

              <Box className="displayCenter" style={{ gap: "7px" }}>
                {auth.userLoggedIn && (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      className="TopbarButton"
                      color="secondary"
                      style={{
                        whiteSpace: "pre",
                        padding: "10px 13px",
                        height: "auto",
                      }}
                    >
                      <img
                        src="images/walletIcon.svg"
                        alt="iamge"
                        height="26px"
                        className={walleticon}
                      />
                      &nbsp;
                      <div id="balance1"></div>
                      {/* {balance ? `${numberCompactFormat(balance)}` : "0"}{" "} */}
                      {auth?.blcLoad
                        ? "..."
                        : auth?.viewWalletData?.balance
                          ? parseFloat(auth?.viewWalletData?.balance).toFixed(2)
                          : "0"}{" "}
                      {currencyUnit}
                    </Button>
                    <Box style={{ marginRight: "8px" }} className={profileBox}>
                      <SwipeableTemporaryDrawer isBlackJack={true} />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
